<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <div class="row">
                <div class="col-sm-12">
                  <b-button
                    variant="info"
                    block
                    @click="$bvModal.show('modal-tool-multiple')"
                  >
                    Tambah Alat
                  </b-button>
                </div>
                <div class="col-sm-12 pt-2">
                  <table class="table thead-light">
                    <thead class="thead-light">
                      <tr>
                        <th>No</th>
                        <th>Alat</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tr v-for="(tool, i) in toolCollection" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ tool.name }}</td>
                      <td>
                        <b-button
                          class="ml-2"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Hapus"
                          @click="deleteTool(i)"
                          size="sm"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <b-button
                    variant="info"
                    block
                    @click="$bvModal.show('modal-lab-material-multiple')"
                  >
                    Tambah Bahan
                  </b-button>
                </div>
                <div class="col-sm-12 pt-2">
                  <table class="table thead-light">
                    <thead class="thead-light">
                      <tr>
                        <th>No</th>
                        <th>Bahan</th>
                        <th>Jumlah</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tr
                      v-for="(labMaterial, i) in labMaterialCollection"
                      :key="i"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ labMaterial.name }}</td>
                      <td>{{ labMaterial.quantity }}</td>
                      <td>
                        <b-button
                          class="ml-2"
                          variant="success"
                          v-b-tooltip.hover
                          title="Edit Jumlah"
                          @click="editLabMaterial(i)"
                          size="sm"
                        >
                          <i class="fas fa-edit px-0"></i>
                        </b-button>
                        <b-button
                          class="ml-2"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Hapus"
                          @click="deleteLabMaterial(i)"
                          size="sm"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group id="input-group-buy-date">
                    <label for="input-buy-date">Tanggal Pinjam: </label>
                    <v-menu
                      v-model="menuDate1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <b-form-input
                          id="input-date"
                          v-model="form.borrow_date"
                          label="Tanggal Pinjam:"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></b-form-input>
                      </template>
                      <v-date-picker
                        v-model="form.borrow_date"
                        @input="menuDate1 = false"
                      ></v-date-picker>
                    </v-menu>
                    <small class="text-danger">{{ error.borrow_date }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group id="input-group-inventory-date">
                    <label for="input-inventory-date"
                      >Tanggal Kembali:
                      <em class="text-muted">opsional</em></label
                    >
                    <v-menu
                      v-model="menuDate2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <b-form-input
                          id="input-date"
                          v-model="form.return_date"
                          label="Tanggal Kembali:"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></b-form-input>
                      </template>
                      <v-date-picker
                        v-model="form.return_date"
                        @input="menuDate2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <small class="text-danger">{{ error.return_date }}</small>
                  </b-form-group>
                </div>
              </div>

              <!-- <b-form-group id="input-group-buy-date" v-if="purpose == 'add'">
                <label for="input-buy-date">Tanggal Pinjam: </label>
                <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-date"
                      v-model="form.borrow_date"
                      label="Tanggal Pinjam:"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="form.borrow_date"
                    @input="menuDate1 = false"
                  ></v-date-picker>
                </v-menu>
                <small class="text-danger">{{ error.borrow_date }}</small>
              </b-form-group> -->
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/letters/lab-tool-loan-application/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <ModalToolMultiple
      @chooseTool="chooseTool"
      @unCheckTool="unCheckTool"
      :toolCollection="toolCollection"
    />
    <ModalLabMaterialMultiple
      @chooseLabMaterial="chooseLabMaterial"
      @unCheckLabMaterial="unCheckLabMaterial"
      :labMaterialCollection="labMaterialCollection"
    />

    <!-- Modal Quantity -->
    <b-modal
      id="modal-lab-material-quantity"
      @ok="addLabMaterial"
      @cancel="cancelModal"
    >
      <template #modal-title>Form Jumlah Bahan</template>
      <b-form-group
        id="input-group-quantity"
        label="Jumlah:"
        label-for="input-quantity"
      >
        <b-form-input
          id="input-quantity"
          v-model="labMaterialCollectionForm.quantity"
          placeholder="Jumlah"
          type="number"
          required
        ></b-form-input>
        <small class="text-danger">{{
          labMaterialCollectionError.quantity
        }}</small>
      </b-form-group>
    </b-modal>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalToolMultiple from "@/view/components/general/ModalToolMultiple.vue";
import ModalLabMaterialMultiple from "@/view/components/general/ModalLabMaterialMultiple.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
    ModalToolMultiple,
    ModalLabMaterialMultiple,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        academic_mentor: "",
        document_type: "",
        description: "",
        sign_image: "",
      },
      menuDate1: false,
      menuDate2: false,
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      toolCollection: [],
      toolCollectionDeleted: [],
      toolCollectionForm: {
        id: "",
        name: "",
        borrower_status_id: 1,
        return_date: null,
        tool_status_id: 0,
      },
      toolPurpose: "add",
      labMaterialCollection: [],
      labMaterialCollectionDeleted: [],
      labMaterialCollectionForm: {
        id: "",
        name: "",
        quantity: "",
        remaining_stock: "",
      },
      labMaterialCollectionError: {
        id: "",
        name: "",
        quantity: "",
        remaining_stock: "",
      },
      labMaterialIndex: "",
      labMaterialPurpose: "add",
    };
  },
  methods: {
    // TOOL
    chooseTool(data) {
      this.toolPurpose = "add";
      this.toolCollectionForm.id = data.id;
      this.toolCollectionForm.name = data.name;
      let clone = { ...this.toolCollectionForm };
      this.toolCollection.push(clone);
    },
    unCheckTool(value) {
      for (let a = 0; a < this.toolCollection.length; a++) {
        if (this.toolCollection[a] == value.id) {
          this.toolCollection.splice(a, 1);
        }
      }
    },
    deleteTool(index) {
      this.toolCollectionDeleted.push(this.toolCollection[index]);
      this.toolCollection.splice(index, 1);
    },
    // LAB MATERIAL
    chooseLabMaterial(data) {
      this.labMaterialPurpose = "add";
      this.labMaterialCollectionForm.name = data.name;
      this.labMaterialCollectionForm.id = data.id;
      this.labMaterialCollectionForm.remaining_stock = data.remaining_stock;
      this.labMaterialCollectionForm.quantity = "";
      this.$bvModal.show("modal-lab-material-quantity");
    },
    validationLabMaterialQuantity() {
      let status = true;
      if (this.labMaterialCollectionForm.quantity == "") {
        status = false;
        this.labMaterialCollectionError.quantity = "Kolom jumlah wajib diisi";
      } else {
        this.labMaterialCollectionError.quantity = "";
      }

      if (
        this.labMaterialCollectionForm.quantity >
        this.labMaterialCollectionForm.remaining_stock
      ) {
        status = false;
        this.labMaterialCollectionError.quantity = `Stok bahan hanya tersisa ${this.labMaterialCollectionForm.remaining_stock}`;
      } else {
        this.labMaterialCollectionError.quantity = "";
      }

      return status;
    },
    addLabMaterial(bvModalEvt) {
      if (this.validationLabMaterialQuantity() == false) {
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validation error");
      }

      if (this.labMaterialPurpose == "add") {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        let clone = { ...this.labMaterialCollectionForm };
        this.labMaterialCollection.push(clone);
        this.$toast.success("Berhasil tambah bahan");
      } else {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        this.$toast.success("Berhasil edit jumlah bahan");
        // this.labMaterialCollection[this.labMaterialIndex].name = // this.labMaterialCollection.name;
        this.labMaterialCollection[this.labMaterialIndex].quantity =
          this.labMaterialCollectionForm.quantity;
      }
      this.$bvModal.hide("modal-lab-material-quantity");
      this.resetLabMaterial();
    },
    cancelModal() {
      this.$root.$emit("cancelModal", this.labMaterialCollectionForm);
    },
    resetLabMaterial() {
      this.labMaterialCollectionForm.quantity = "";
    },
    unCheckLabMaterial(value) {
      for (let a = 0; a < this.labMaterialCollection.length; a++) {
        if (this.labMaterialCollection[a].id == value.id) {
          this.labMaterialCollection.splice(a, 1);
        }
      }
    },
    deleteLabMaterial(index) {
      if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
        this.setOldLabMaterialCollection();
      }
      this.labMaterialCollection.splice(index, 1);
    },
    editLabMaterial(i) {
      this.labMaterialPurpose = "edit";
      this.labMaterialIndex = i;

      this.labMaterialCollectionForm.id = this.labMaterialCollection[i].id;
      this.labMaterialCollectionForm.name = this.labMaterialCollection[i].name;
      this.labMaterialCollectionForm.quantity =
        this.labMaterialCollection[i].quantity;
      this.labMaterialCollectionForm.remaining_stock =
        this.labMaterialCollection[i].remaining_stock;

      this.$bvModal.show("modal-lab-material-quantity");
    },
    setOldLabMaterialCollection() {
      this.form.oldLabMaterialCollectionFlag = JSON.stringify(
        this.form.lab_material_id
      );
      this.formData.append(
        "oldLabMaterialCollection",
        JSON.stringify(this.form.lab_material_id)
      );
    },
    async getRoomOption() {
      //ganti
      let response = await module.setTreeSelectRoom2(
        "api/rooms",
        "?role_id=1&room_type_id=1"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.room_id = response.data;
        this.room_id.unshift({
          label: "Pilih Lab",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTeacherOption() {
      //ganti
      let response = await module.setTreeSelect("api/users", "?role_id=1");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teacher_mentor = response.data;
        this.teacher_mentor.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambar TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue")
      //     console.log('send emit')
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse")
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      this.form.tool_id = JSON.stringify(this.toolCollection);
      this.form.lab_material_id = JSON.stringify(this.labMaterialCollection);

      if (this.purpose == "edit" && this.toolCollectionDeleted.length > 0) {
        this.form.toolCollectionDeleted = JSON.stringify(
          this.toolCollectionDeleted
        );
      }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        this.form.tool_id = JSON.parse(this.toolCollection);
        this.form.lab_material_id = JSON.parse(this.labMaterialCollection);
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/lab-tool-loan-application/list");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
    this.getTeacherOption();
    this.getRoomOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;

        this.toolCollection = this.form.tool_id;
        this.labMaterialCollection = this.form.lab_material_id;

      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>